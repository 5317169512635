<template>
  <qtm-content-block collapsible :title="title">
    <template v-slot:title:append>
      <slot name="title:append" />
    </template>
    <template v-slot:top-right>
      <slot name="top-right" />
    </template>
    <div class="d-flex justify-space-between">
      <div>
        <slot name="items:prepend-left" />
      </div>
      <div v-if="items.length" class="mb-4">
        <span class="font-weight-bold text-secondary">
          {{ items.length }} total
        </span>
        ({{ purchaseItems.length }} purchase<span v-if="purchaseItems.length > 1">s</span>,
        {{ rentalItems.length }} rental<span v-if="rentalItems.length > 1">s</span>)
      </div>
    </div>

    <v-alert
      v-if="error"
      border="start"
      class="mb-6"
      icon="mdi-information-outline"
      type="error"
      variant="outlined"
    >
      <span v-if="hasItems">
        Please make sure you complete all the necessary information.
      </span>
      <span v-else>
        Please add at least 1 order item.
      </span>
    </v-alert>

    <slot name="items" :purchase-items="purchaseItems" :rental-items="rentalItems">
      <cart-items-table
        v-if="purchaseItems.length"
        ref="purchaseCart"
        :boms="boms"
        :class="{ 'mr-n4': overflow }"
        :cost-codes="costCodes"
        data-test="purchase-items"
        :disabled="disabled"
        :edit-only="editOnly"
        :force-cost-code-select="forceCostCodeSelect"
        :item-number-field="itemNumberField"
        :items="purchaseItems"
        :rearrangeable="rearrangeable"
        :require-cost-codes="requireCostCodes"
        :taxes="taxes"
        :units="units"
        :validate-units="validateUnits"
        :with-cost-codes="withCostCodes"
        :with-prices="withPrices"
        @cost-code-changed="updateCostCodes"
        @insert="insertItem"
        @remove="removeItem"
        @sort:items="handleItemSort(purchaseItems, $event)"
        @tax-changed="updateDefaultTax"
      />
      <div v-if="canAddItems">
        <qtm-btn
          class="mb-6 ml-n3 mt-4 px-1"
          data-test="add-purchase-item-btn"
          tertiary
          @click="addPurchaseItem"
        >
          <v-icon size="large" location="left">
            mdi-plus
          </v-icon>
          Add Purchase Item
        </qtm-btn>
      </div>
      <div v-else-if="purchaseItems.length" class="mb-6" />

      <slot name="purchase-items-append" />

      <cart-items-table
        v-if="rentalItems.length"
        ref="rentalCart"
        :boms="boms"
        :class="{ 'mr-n4': overflow }"
        :cost-codes="costCodes"
        data-test="rental-items"
        :disabled="disabled"
        :edit-only="editOnly"
        :force-cost-code-select="forceCostCodeSelect"
        :item-number-field="itemNumberField"
        :items="rentalItems"
        rental
        :rearrangeable="rearrangeable"
        :require-cost-codes="requireCostCodes"
        :taxes="taxes"
        :units="units"
        :validate-units="validateUnits"
        :with-cost-codes="withCostCodes"
        :with-prices="withPrices"
        @cost-code-changed="updateCostCodes"
        @insert="insertItem"
        @remove="removeItem"
        @sort:items="handleItemSort(rentalItems, $event)"
        @tax-changed="updateDefaultTax"
      />
      <div v-if="canAddItems">
        <qtm-btn class="ml-n3 mt-4 px-1" data-test="add-rental-item-btn" tertiary @click="addRentalItem">
          <v-icon size="large" location="left">
            mdi-plus
          </v-icon>
          Add Rental Item
        </qtm-btn>
      </div>
    </slot>

    <slot name="items-append" />

    <!-- Deprecated but needed for historic orders -->
    <order-freight-charges
      v-if="deliveryRequired && hasFreightCharges"
      ref="orderFreightChargesRef"
      class="mt-8"
      :cost-code="freightCostCode"
      :cost-code-required="freightCostCodeRequired"
      :cost-codes="costCodes"
      :delivery-charge="deliveryCharge"
      :force-cost-code-select="forceCostCodeSelect"
      :hide-charges="!withPrices"
      :pickup-charge="pickupCharge"
      :tax="freightTax"
      :taxes="taxes"
      :with-cost-code="withCostCodes"
      @update:cost-code="$emit('update:freight-cost-code', $event)"
      @update:delivery-charge="$emit('update:delivery-charge', $event)"
      @update:pickup-charge="$emit('update:pickup-charge', $event)"
      @update:tax="$emit('update:freight-tax', $event)"
    />

    <slot />
  </qtm-content-block>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'
import type { LineItem } from '@quotetome/materials-api'
import CartItemsTable from '@/components/orders/cart-items-table.vue'
import OrderFreightCharges from '@/components/orders/order-freight-charges.vue'
import useValidation from '@/composables/validation'

interface WorkingItem extends LineItem {
  index?: number
}

export interface Props {
  boms?: any[]
  costCodes?: any[]
  deliveryCharge?: number | string | null
  deliveryRequired?: boolean
  disabled?: boolean
  editOnly?: boolean
  forceCostCodeSelect?: boolean
  freightCostCode?: string
  freightCostCodeRequired?: boolean
  freightTax?: number | null
  itemDefaults?: WorkingItem
  itemNumberField?: 'index' | 'item_number'
  items: WorkingItem[]
  overflow?: boolean
  pickupCharge?: number | string | null
  rearrangeable?: boolean
  requireCostCodes?: boolean
  restrictAdd?: boolean
  taxes?: any[]
  title?: string
  units?: any[]
  validate?: boolean
  validateUnits?: boolean
  withCostCodes?: boolean
  withPrices?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  boms: () => [],
  costCodes: () => [],
  deliveryCharge: undefined,
  deliveryRequired: false,
  disabled: false,
  forceCostCodeSelect: false,
  freightCostCode: '',
  freightCostCodeRequired: false,
  freightTax: undefined,
  itemDefaults: () => ({
    id: '',
    comment: '',
    cost_code: '',
    day_rate: null,
    description: '',
    has_rental_duration: false,
    month_rate: null,
    quantity: 1,
    reference_identifier: undefined,
    rental_duration: null,
    rental_duration_unit: null,
    rental_price: null,
    unit: 'Each',
    unit_price: null,
    week_rate: null,
  }),
  itemNumberField: 'index',
  overflow: true,
  pickupCharge: undefined,
  rearrangeable: false,
  requireCostCodes: false,
  restrictAdd: false,
  taxes: () => [],
  title: 'Order Items',
  units: () => [],
  validate: true,
  validateUnits: true,
  withCostCodes: true,
  withPrices: false,
})

const emit = defineEmits([
  'update:delivery-charge',
  'update:freight-cost-code',
  'update:freight-tax',
  'update:items',
  'update:pickup-charge',
])

const { v$ } = useValidation({ rules: { items: () => hasItems.value }, state: toRefs(props) })

const costCode = ref('')
const defaultTax = ref()
const error = ref(false)
const hasFreightCharges = ref(Boolean(props.deliveryCharge || props.pickupCharge))

const authStore = useAuthStore()
const canCreatePO = computed(() => authStore.canCreatePO)

const sortedItems = computed(() => {
  const purchaseItems = props.items.filter(item => !item.rental_duration_unit)
  const rentalItems = props.items.filter(item => item.rental_duration_unit)
  const items = purchaseItems.concat(rentalItems)

  items.forEach((item, index) => {
    item.index = index + 1
  })

  return items
})

const canAddItems = computed(() => !props.disabled && !props.editOnly && (!props.restrictAdd || canCreatePO.value))
const hasItems = computed(() => props.items.length > 0)
const purchaseItems = computed(() => sortedItems.value.filter(item => !item.rental_duration_unit))
const rentalItems = computed(() => sortedItems.value.filter(item => item.rental_duration_unit))

const addItem = (rental = false) => {
  const item: WorkingItem = {
    ...props.itemDefaults,
    id: uuidv4(),
    cost_code: costCode.value,
    has_rental_duration: rental,
    rental_duration: rental ? 1 : null,
    rental_duration_unit: rental ? 'days' : null,
  }

  if (props.units.length) {
    item.unit = props.units.find(unit => unit.is_default)?.code
  }

  if (props.taxes.length === 1) {
    item.tax = props.taxes[0].id
  }
  else {
    item.tax = defaultTax.value
  }

  emit('update:items', props.items.concat([item]))
}

const purchaseCart = ref<InstanceType<typeof CartItemsTable>>()
const rentalCart = ref<InstanceType<typeof CartItemsTable>>()

const addPurchaseItem = () => {
  addItem()
  nextTick(() => purchaseCart.value?.focusLastItem())
}
const addRentalItem = () => {
  addItem(true)
  nextTick(() => rentalCart.value?.focusLastItem())
}

const clear = () => {
  let i = props.items.length

  while (i > 0) {
    i -= 1
    const item = props.items[i]

    if (!(item.description || item.unit_price || item.day_rate || item.week_rate || item.month_rate || item.comment)) {
      props.items.splice(i, 1)
    }
  }

  emit('update:items', props.items)
}

const handleItemSort = (filteredArray: WorkingItem[], event: any) => {
  if (event.moved) {
    const movedItem = event.moved.element
    const movedToLastPosition = event.moved.newIndex >= filteredArray.length - 1
    const offset = movedToLastPosition ? -1 : 1
    const adjustment = movedToLastPosition ? 1 : 0
    const items = [...props.items]
    const oldIndex = items.findIndex(i => i.id === movedItem.id)

    items.splice(oldIndex, 1)

    const newIndex = items.findIndex(i => i.id === filteredArray[event.moved.newIndex + offset].id)

    items.splice(newIndex + adjustment, 0, movedItem)

    emit('update:items', items)
  }
}

const insertItem = (event: any) => {
  let index = props.items.findIndex(item => item.id === event.item.id)

  if (event.location === 'below') {
    index += 1
  }

  const items = [...props.items]
  const rental = !!event.item.rental_duration_unit

  items.splice(index, 0, {
    ...props.itemDefaults,
    id: uuidv4(),
    cost_code: costCode.value,
    has_rental_duration: rental,
    rental_duration: rental ? 1 : null,
    rental_duration_unit: rental ? 'days' : null,
    tax: defaultTax.value,
    unit: props.units.find(unit => unit.is_default)?.code || 'Each',
  })

  emit('update:items', items)
}

const removeItem = (item: LineItem) => {
  emit('update:items', props.items.filter(i => i.id !== item.id))
}

const updateCostCodes = (cc: string) => {
  costCode.value = cc
  props.items.filter(item => !item.cost_code).forEach(item => {
    item.cost_code = cc
  })
}

const updateDefaultTax = (tax: any) => {
  defaultTax.value = tax
  props.items.filter(item => !item.tax).forEach(item => {
    item.tax = tax
  })
}

const orderFreightChargesRef = ref<InstanceType<typeof OrderFreightCharges>>()

const isValid = () => {
  if (!props.validate) {
    return true
  }

  v$.value.$touch()

  const toValidate = [purchaseCart.value, rentalCart.value, orderFreightChargesRef.value]
  const valid = toValidate.filter(c => c).map(c => c!.isValid()).concat([!v$.value.$invalid]).every(x => x)

  error.value = !valid

  return valid
}

defineExpose({ clear, isValid })
</script>
