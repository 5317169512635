
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexB_JFzTrHKOxKCfg98NsHuB9u88BRvwFHHo3pJyQgpCEMeta } from "/opt/build/repo/pages/admin/index.vue?macro=true";
import { default as indexq2fd2aMW50PgihCHyNqajmt7iEfDMBXPcDeG0K0MfSQMeta } from "/opt/build/repo/pages/admin/emails/index.vue?macro=true";
import { default as indexfw2T8wfC2giJqhKRrBIx0I5xFCxpaJiSs7pxlYfFbF0Meta } from "/opt/build/repo/pages/admin/dashboard/index.vue?macro=true";
import { default as adminoDxSfL1zfbtXOZhTEjupMIsD_45tLPMDhEkobqeqFztjkMeta } from "/opt/build/repo/pages/admin.vue?macro=true";
import { default as indexaAs6F0uOO1PbNxqH8DD_45e04gSZQCarVD9TCXJYV94KEMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as logindGU_Y4tTcWsCg9HnKfbgxniTJNnqn8Xqf4N3mbSf_459QMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as indexDIW2LinbEFpGSugwCWnZYq8_45jH3EeICDtrF3PhD37bcMeta } from "/opt/build/repo/pages/profile/index.vue?macro=true";
import { default as passwordq6FFgVgiVguIArIZ7rSOLDEvdJ9R1zBHou0fT_45k8VbwMeta } from "/opt/build/repo/pages/profile/password.vue?macro=true";
import { default as notificationsf2ETpNLsvB1Ri_45ZRP0LpP10gQ0lYmJO4e_45ucu484YwwMeta } from "/opt/build/repo/pages/profile/notifications.vue?macro=true";
import { default as profileYXN2cSixlL75SwObUUSDSPm9Xr5hPfQHYdM_45txMKyrEMeta } from "/opt/build/repo/pages/profile.vue?macro=true";
import { default as newrdml_1mzlnUWTlTZ2Ed__45BXGp_QjRrHaVUFx6N77Ok4Meta } from "/opt/build/repo/pages/expenses/new.vue?macro=true";
import { default as editGyF4glqLoO1oRVFhuraXz_45uMijB0zpopo71gf8bY7ccMeta } from "/opt/build/repo/pages/expenses/[id]/edit.vue?macro=true";
import { default as indexDIpZGjfNwNakU1hR4Zv6TS99amsnVP1ev11hZfugjuQMeta } from "/opt/build/repo/pages/expenses/[id]/index.vue?macro=true";
import { default as _91id_93LL7vsE0nSFJredRTWbC17m91d84hBmm7nNBQIbr0TA8Meta } from "/opt/build/repo/pages/expenses/[id].vue?macro=true";
import { default as indexJogy5zQnoBuieLELz73guCnBlGfxwlBx3uADvEB607wMeta } from "/opt/build/repo/pages/expenses/index.vue?macro=true";
import { default as exportYOkgHnDUZSZ9J2LdRTnYamgC_45SoXy7RMcPZXzeRrPYMMeta } from "/opt/build/repo/pages/expenses/export.vue?macro=true";
import { default as expenseszSYrxJ4pkAgmqya1zPeOh5mSY6YFECca_M_bshqH6SIMeta } from "/opt/build/repo/pages/expenses.vue?macro=true";
import { default as inboxuA4dxwUcQeKZXiDrJBejpV45gNTzhdH0_45LidWzkwFooMeta } from "/opt/build/repo/pages/invoices/inbox.vue?macro=true";
import { default as export_45CBDfSSF2_45m5_45BuOy6sNjqoKxTI_45TDsX70z8d8DmIPsMeta } from "/opt/build/repo/pages/invoices/index/export.vue?macro=true";
import { default as index0Di72goBjyLuq31l5hhV78_45uqtDkILlexkn05WIHbGAMeta } from "/opt/build/repo/pages/invoices/index.vue?macro=true";
import { default as invoicesksZypTfdfNkd37B_45ULP3MP9EobbXZ_45Air6nIR1UYV2MMeta } from "/opt/build/repo/pages/invoices.vue?macro=true";
import { default as alln8xByMpaxCVgHI9057Twuvy1NLmFqu_kWckVLGIuv9kMeta } from "/opt/build/repo/pages/dashboard/all.vue?macro=true";
import { default as index8xoVnVnRZ_kyMH7QwvT3ZfFyN62Xk1mA8ppzVlBL6wkMeta } from "/opt/build/repo/pages/dashboard/index.vue?macro=true";
import { default as my_45orders8YPSSqDjzT9rqP3eMWco_ZgsnEKJPJ00BWosJOkrh1cMeta } from "/opt/build/repo/pages/dashboard/my-orders.vue?macro=true";
import { default as dashboarddIGQPcp2ZQEz5u2XKJHyyygOFdfmIecOhuIjtCy_6i0Meta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as indexvf0_45Sg85pNIB1SuxXQVdFlq15sXgcWr4xRiNQrr2tvAMeta } from "/opt/build/repo/pages/suppliers/new/index.vue?macro=true";
import { default as categoriesECVJXF_y8XkxokoT4lLN6_45f22XlalkOfu1_eypoOaF8Meta } from "/opt/build/repo/pages/suppliers/new/categories.vue?macro=true";
import { default as newHTOyKBjDULQdMQjN1SaQDCqZ8QB2Uu1pYHnyhLHwAuAMeta } from "/opt/build/repo/pages/suppliers/new/contacts/new.vue?macro=true";
import { default as index0VbW7c9AxhwF5968BVK5ekeitwY68o019JlzrtzbtuoMeta } from "/opt/build/repo/pages/suppliers/new/contacts/index.vue?macro=true";
import { default as new5tuZc0YebIKdTKEs_LUdmG_45UqIVEYHx49D0i6yG6bf0Meta } from "/opt/build/repo/pages/suppliers/new.vue?macro=true";
import { default as indexKLspPrBQjdzRXmvyOck9aYNuTq05FDJ6hSkanNvFAjgMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/index.vue?macro=true";
import { default as companiesoBqC_451J6bM6v_45_xM7pWYEiPfLOfft29ysMErmVqEgE8Meta } from "/opt/build/repo/pages/suppliers/[id]/edit/companies.vue?macro=true";
import { default as categoriesIqfzOKi0bFlH8QBIQlcJFQjLpnM5ItB8plgQ66euP_45MMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/categories.vue?macro=true";
import { default as new3rLwDtGp6jaAwpIjbCogyoUokR5uEir4dJDaePgErPcMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/contacts/new.vue?macro=true";
import { default as indexp1ROVjDXuGmhD3RMmpOB81qIx7evLziDNIWQ4F1kBA4Meta } from "/opt/build/repo/pages/suppliers/[id]/edit/contacts/index.vue?macro=true";
import { default as _91contactId_93SvLXQgmI3B1VJ6rCta_GATdxbrMLYomOa3k613Y21_QMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/contacts/[contactId].vue?macro=true";
import { default as editOxXru9nr3qSUpW7YNsqSdT0Ab_pQtEMFQtlRkacIVcwMeta } from "/opt/build/repo/pages/suppliers/[id]/edit.vue?macro=true";
import { default as merge3Xt_45LOnUV_45D3NKHD4ebvOZQmShie2rmN82wwRvJgzRMMeta } from "/opt/build/repo/pages/suppliers/[id]/merge.vue?macro=true";
import { default as _91id_934vh_IZuUOs8Td19q8DLCB06kMFqK1XcwgYZPoTKYW6QMeta } from "/opt/build/repo/pages/suppliers/[id].vue?macro=true";
import { default as indexHPm36GYhG7xR75dE95VHYFNesEcDpmlydwwTbERTQrYMeta } from "/opt/build/repo/pages/suppliers/index.vue?macro=true";
import { default as suppliers6P25SzaXCJvxgtJhD7nKcsWhzypD51y2KJkd3W_qc2oMeta } from "/opt/build/repo/pages/suppliers.vue?macro=true";
import { default as new6nHWqb7LCv1Qrq3Qc2QOvIi0eQr1a6OPovY3LHqXSiQMeta } from "/opt/build/repo/pages/users/new.vue?macro=true";
import { default as indexfED4W_459Q6ddfmEYJcUC03tm4ucdusFvy_45W7AZpct_45JgMeta } from "/opt/build/repo/pages/accounting/index.vue?macro=true";
import { default as pending_45syncFAAerXs5Wz5_45fXtif_8GpJtmqVGQ_45HFxyjQ04hrt0jwMeta } from "/opt/build/repo/pages/accounting/pending-sync.vue?macro=true";
import { default as accountingf_OXI0_pNwENm_45qDFv4BWI64aTXFhkdmjkEwn28zUcwMeta } from "/opt/build/repo/pages/accounting.vue?macro=true";
import { default as componentseh7D6mnBjAg7IfCAGQxCfAmMQv2_LEwhjTfpW5a_6jwMeta } from "/opt/build/repo/pages/components.vue?macro=true";
import { default as indexCAO3PhKr3GuCFoH_N_LL5pWI9UlMX2F5EbBoq1APUYMMeta } from "/opt/build/repo/pages/orders/new/index.vue?macro=true";
import { default as reviewFglkWa0SPWc0V_FX_450xDfSCsd9_U_3lR8JFn_45aya8awMeta } from "/opt/build/repo/pages/orders/new/review.vue?macro=true";
import { default as deliveryIM7RLjm61fBspsJxp8xlI24oiQxx_mrXsZV4ce652IgMeta } from "/opt/build/repo/pages/orders/new/delivery.vue?macro=true";
import { default as invite_45suppliersByk3MW6kpGvz9BEAZO9boGZVi8WcsGmxw6_4MyPmFS8Meta } from "/opt/build/repo/pages/orders/new/invite-suppliers.vue?macro=true";
import { default as newCn5JSGaSW5Odvg8ELyE8by_18bLinxlbGNp5FnIC4Y4Meta } from "/opt/build/repo/pages/orders/new.vue?macro=true";
import { default as setup_45modeKCX_45KSWxUOEtQYnBafsiW3PMOmzp9L9Uxj3uCjVxcBwMeta } from "/opt/build/repo/pages/setup-mode.vue?macro=true";
import { default as editg4mlNhZXgBOeDDowuVD0Dqtp8FIJFggRtpk6GKwHNQsMeta } from "/opt/build/repo/pages/orders/[id]/edit.vue?macro=true";
import { default as indexwb3oaZaBpW3qTutPi60GDxHlePDeokpCMS8RpjzJVi0Meta } from "/opt/build/repo/pages/orders/[id]/index.vue?macro=true";
import { default as fullsBE6bfV5Ufw1DDWIoFaxmJViXOPbAYhPHF921MxkWjEMeta } from "/opt/build/repo/pages/orders/[id]/receive/full.vue?macro=true";
import { default as indexzBlbXlUsoKdgbFOLHq_ZBD_F7g8gZI1gQBjL1byuz84Meta } from "/opt/build/repo/pages/orders/[id]/receive/index.vue?macro=true";
import { default as submitmULsTbuZaDUxIcSOr8TKX1mWa81qgAosXvQaZorVf94Meta } from "/opt/build/repo/pages/orders/[id]/receive/submit.vue?macro=true";
import { default as partialuvp2G85tMmj33OMmytZHzQZ30PGtJprJ04urHG0QjGcMeta } from "/opt/build/repo/pages/orders/[id]/receive/partial.vue?macro=true";
import { default as receiveRdOoF_2bF5xTx9Ek3QfW8_WwbBzI8_45XoO_45CeVKN7_D0Meta } from "/opt/build/repo/pages/orders/[id]/receive.vue?macro=true";
import { default as in_45store4FiL6PEldRkMv1Cw4TkzAaH8o2Iejii_45R4I9WoRDNiwMeta } from "/opt/build/repo/pages/orders/[id]/in-store.vue?macro=true";
import { default as bom_45pricinguapcRgjTvViuJVn8eaHf_KRO5a8MeCx9famZ7XrPhkQMeta } from "/opt/build/repo/pages/orders/[id]/bom-pricing.vue?macro=true";
import { default as index_456jrjMbWIei2Rdqja6aoJXdPX8D6gh9yULA7TjMZFxEMeta } from "/opt/build/repo/pages/orders/[id]/change-order/index.vue?macro=true";
import { default as bom_45itemsk12HKFRCUKdQkYaPfC2GDBkx9XsO_45XiFGv0zubnO4vAMeta } from "/opt/build/repo/pages/orders/[id]/change-order/bom-items.vue?macro=true";
import { default as change_45orderWcrJ28nSWAeoEy9Ba3xbMbQ0yFu_OS0JXleX0hAYf9sMeta } from "/opt/build/repo/pages/orders/[id]/change-order.vue?macro=true";
import { default as indexQ98wKdXUdLJLvCpmjYXW6Z8Qq_454ZDy9l3SdXo1IWhlMMeta } from "/opt/build/repo/pages/orders/[id]/complete-draft/index.vue?macro=true";
import { default as bom_45itemsr2VASGPugj6lrUReim2Od0XqTlCbIOq6Y7q2QBbvYIMMeta } from "/opt/build/repo/pages/orders/[id]/complete-draft/bom-items.vue?macro=true";
import { default as complete_45draftLYD_2C85VuyTkL_45XTXZcjXjSPKpvg3_BKAVizj44DssMeta } from "/opt/build/repo/pages/orders/[id]/complete-draft.vue?macro=true";
import { default as _91quote_930CvDzyGejnpmE712DH2g4wzdvxkaTuWkGXiJ5BzkSMAMeta } from "/opt/build/repo/pages/orders/[id]/quotes/[quote].vue?macro=true";
import { default as invite_45suppliersCaDP_45v8VUREpyUjgDDLS_kkvDxbFvOQqzIn4cbeYdcQMeta } from "/opt/build/repo/pages/orders/[id]/invite-suppliers.vue?macro=true";
import { default as _91invoice_93e55pWBSDsYHhooy1bSEpc_7kIt9UInf_OD2H9vztcpAMeta } from "/opt/build/repo/pages/orders/[id]/invoices/[invoice].vue?macro=true";
import { default as _91id_93dWQGHkP2ZiXwthg3lYEbXH_45RM9VptslrhcqPfONkXLwMeta } from "/opt/build/repo/pages/orders/[id].vue?macro=true";
import { default as index1mG8JMQgCyyObX5use_45xFbRcEJCfqtvHcFdfbQ88imoMeta } from "/opt/build/repo/pages/users/index.vue?macro=true";
import { default as indexOlWd9vFHguHOsQSBcDz6x2XeFrcv2t5_45c0Q9Cv_45IO0oMeta } from "/opt/build/repo/pages/orders/index.vue?macro=true";
import { default as newjprGiRrRDrv4NOAm0bLS9GBcvkWEGuX_45NolO3FeAX3kMeta } from "/opt/build/repo/pages/projects/new.vue?macro=true";
import { default as indexXoVDMAVTEyl8a1ggmhvqlQZgtU9DN5JR8JlXeh3wox8Meta } from "/opt/build/repo/pages/requisitions/index.vue?macro=true";
import { default as indexnAKVgOczf25AS5BM5MNTvnnSDIoJLkFNj6AG55Q_45I94Meta } from "/opt/build/repo/pages/requisitions/[id]/index.vue?macro=true";
import { default as requisitionsO_45Ch7ICMFhaShjRCDBpEqzh8VR8_45lakmBQ98H415idQMeta } from "/opt/build/repo/pages/requisitions.vue?macro=true";
import { default as indexBX58bQISY1VYo_Wz8CU5cAz0R5YuROecMIV6BeXO8K4Meta } from "/opt/build/repo/pages/projects/[id]/index.vue?macro=true";
import { default as indexprIyOdRTqfxRzolNK253knIhoKkRG2WoL_45zEVhsfj0YMeta } from "/opt/build/repo/pages/projects/[id]/boms/[bomId]/index.vue?macro=true";
import { default as ordersbtAwGcO3emqC2BdXj3G1oaPK8zcUgGeUAeFpgh56koIMeta } from "/opt/build/repo/pages/projects/[id]/boms/[bomId]/orders.vue?macro=true";
import { default as _91bomId_93VuOpXo_TnMpXTnST3V4sErEOvuQaAc_LsWpUA7pRCcMMeta } from "/opt/build/repo/pages/projects/[id]/boms/[bomId].vue?macro=true";
import { default as _91id_93Hz9G7CVSytVYMe3Ox5AziiJMxgFROVbocG1I3Lb9q5MMeta } from "/opt/build/repo/pages/projects/[id].vue?macro=true";
import { default as indexNi7MwMlvAgQAeRYu77U2tQItJZz3u8BASnHH0_kgyrwMeta } from "/opt/build/repo/pages/companies/[id]/index.vue?macro=true";
import { default as rolesxQ4gDWhUooeu_bhyG46rBcjBwsGIm6iTi73UZbzHERkMeta } from "/opt/build/repo/pages/companies/[id]/roles.vue?macro=true";
import { default as contactsyn_7Kx0hxgdJ4l18KBLiA_v3IhkJgEUo88LpSWAEIgQMeta } from "/opt/build/repo/pages/companies/[id]/contacts.vue?macro=true";
import { default as cost_45codesT5gltt1L0JqAvKx78rFQP2QNe5KXnApWC2u5hHwc9x8Meta } from "/opt/build/repo/pages/companies/[id]/cost-codes.vue?macro=true";
import { default as restricteda77Tx3jSab_453rCdoyxlkU3llUogGjeYFQSB3CcW_45z8cMeta } from "/opt/build/repo/pages/companies/[id]/restricted.vue?macro=true";
import { default as report_45confign7xmowKU2fNXJu1BjjZA_9GqXM4BhiP_45Qzc2z858V10Meta } from "/opt/build/repo/pages/companies/[id]/report-config.vue?macro=true";
import { default as purchase_45orderyNL19068HeqxenKy7rCkLLuf1lcos810hm7Izlvt6A8Meta } from "/opt/build/repo/pages/companies/[id]/purchase-order.vue?macro=true";
import { default as index7zRULB6gZIH0r5NNoDr8yIF1GZYiC1Y6AIIMghtlUhMMeta } from "/opt/build/repo/pages/companies/[id]/accounting/index.vue?macro=true";
import { default as setupihkskdtKJn_45hG4oNJ_sar0Bl6Gfa5hLi7ZujbOIYOZAMeta } from "/opt/build/repo/pages/companies/[id]/accounting/setup.vue?macro=true";
import { default as _91id_93DhQeBoA4kM2PPi3xms_45_wQbSiAYh4f7fFiwIVmhB9JYMeta } from "/opt/build/repo/pages/companies/[id].vue?macro=true";
import { default as indexJxu2jFkvSCWJf6gZ2nAdBaEA0U3aznS_LcN5i2h40XgMeta } from "/opt/build/repo/pages/projects/index.vue?macro=true";
import { default as indexndk6FEerfNyO_CWJ3GLhnVNy7_aMRtQv6puBR11NsXwMeta } from "/opt/build/repo/pages/forgot-password/index.vue?macro=true";
import { default as check_45emailTsv1F8qpXqyLmG72oAfXsDZYeIQy7eDccVNPdcIgXmQMeta } from "/opt/build/repo/pages/forgot-password/check-email.vue?macro=true";
import { default as resetxvHRO07MLC4OM63E3FqxpRJdftxerT6pdYZbgyRoaPkMeta } from "/opt/build/repo/pages/forgot-password/[uuid]/reset.vue?macro=true";
import { default as forgot_45passwordOIFCeCN4AMh8hgrToHpDpWjsGVd3MBWi_zvta3PbADwMeta } from "/opt/build/repo/pages/forgot-password.vue?macro=true";
import { default as edit4btEqLweB2QXRaj9U1fiz1FH9nLtogs8S_458SnIhq_AkMeta } from "/opt/build/repo/pages/users/[id]/edit.vue?macro=true";
import { default as index_45W_cGPvNZ4K2mhxuFSxIRJM87Ye5vsIuPoUUsDX2r70Meta } from "/opt/build/repo/pages/error-test/index.vue?macro=true";
import { default as index1268R_45zQ_g6sEJmCy39vyYsifXi4Z6qoD_zQPw4MZU4Meta } from "/opt/build/repo/pages/accounting-export/index.vue?macro=true";
import { default as _91order_93XhtOacYcSeXync5UgypVxw7ruElNAssK3_oznu3hNfYMeta } from "/opt/build/repo/pages/accounting-export/[order].vue?macro=true";
import { default as accounting_45exporti_45eQjERxm4NAAeSrtJZIXgyD_iKtbRc8nOaWFXxD5sEMeta } from "/opt/build/repo/pages/accounting-export.vue?macro=true";
import { default as indexuQDTjFQgjNXNJZbmsy7tA5ML720w3DvrniviWdQVtksMeta } from "/opt/build/repo/pages/purchase-orders/new/index.vue?macro=true";
import { default as bom_45itemsiTOOKPan8CNHmasUrrcymjt6xMkCIV6UK0Ylma4DSP4Meta } from "/opt/build/repo/pages/purchase-orders/new/bom-items.vue?macro=true";
import { default as new0qSXK1sVifMz9QtUEj0DKeuXimv0MavYJgtimw4uCmYMeta } from "/opt/build/repo/pages/purchase-orders/new.vue?macro=true";
import { default as index4iEvdLIe7_N_45WfWuhZjpfiNgtrKigc10ftdSWNuEnEgMeta } from "/opt/build/repo/pages/users/accept-invite/[uuid]/index.vue?macro=true";
import { default as newDNcshxx_45VrCdlm8UEYUMGO5YExiIAxkFRuMLqGZ_jkYMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/new.vue?macro=true";
import { default as indexH_KkE6rpKTCHAvvnbTmfZV1uMFdv_gFiPZjboDeMgUYMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/index.vue?macro=true";
import { default as contacts5N4Vaa41tPSOF0RICD_LOmZFNNBYySNKFUAx1H0mwKsMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/contacts.vue?macro=true";
import { default as in_45storeSdxZkeNauqtmf9ZjKD5Lfk09w0GYw7GjBxxmfTkV7FsMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/in-store.vue?macro=true";
import { default as categoriesmjgBymPKlHuhpNDMtkzRe3DXay_9HEiD4yCK3R1H4oQMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/categories.vue?macro=true";
import { default as adduZuFFVVfqIYisRcDnBTpWN05W1lYEfyvHP3DfSaS6hsMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add.vue?macro=true";
import { default as indexUt1Hm_rH2jyUgVRC_45q39pMILfBeZ7IJ6gpiM5JKTcIAMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/index.vue?macro=true";
import { default as maptPZ_45n7DN0eUGEZQPHf3GPd29IgMCcFBI904dCAlrc_wMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/map.vue?macro=true";
import { default as index8DXQdWHdX6xfT9ZsZTCSko01TjcQ2ig5FbPPwEsMPiwMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/index.vue?macro=true";
import { default as contacts8TaB3LOZGmznAaa_D3DFSAmphT1UVSFh8qa1vSKhsN4Meta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/contacts.vue?macro=true";
import { default as accountingW6RorN9MkzhrKCNI1sFDi5dJgX5bXa2a8Cw8KymmEyoMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/accounting.vue?macro=true";
import { default as _91companyVendorId_93YJrVPbKIon5f5liOLS22GIv1ccqwAumJoHuZ_45if_45H8IMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId].vue?macro=true";
import { default as suppliersCg_45qTwztRQ3MMn8dTzFowm2HIsm6elSqDJApT5fKbKgMeta } from "/opt/build/repo/pages/companies/[id]/suppliers.vue?macro=true";
import { default as indexs4QNqrAJrwIeNfURSQ_45GG9EhUE3O0wFMJ6sq6SqnCIoMeta } from "/opt/build/repo/pages/invoices/new/index.vue?macro=true";
import { default as match_45itemslAht2UubwxEUe7xA2h2Zj336QCGPAAuiBWKw6GV9o1AMeta } from "/opt/build/repo/pages/invoices/new/match-items.vue?macro=true";
import { default as newgMUkRG1HRAaZ8RoZepmT7WnRoxwZMGmYvIyZommmfzcMeta } from "/opt/build/repo/pages/invoices/new.vue?macro=true";
import { default as editdbfDomLdcN_45BXNdCb9Yd1rUXxMGI_89vcIhJI_45qIHrIMeta } from "/opt/build/repo/pages/invoices/[id]/edit.vue?macro=true";
import { default as match_45itemsLRjqU9nQNuTvThs1RzJKFa4FhZm_45ekPsMjuznD4zBAoMeta } from "/opt/build/repo/pages/invoices/[id]/match-items.vue?macro=true";
import { default as _91id_93C_45KioyoIitjEJE4tbMUV7enJuENTeabk3i72oWt_taIMeta } from "/opt/build/repo/pages/invoices/[id].vue?macro=true";
import { default as indexEWZPP6Lpqib4P4v7WK_03e5ACFkI71XVN0BCmyelmEoMeta } from "/opt/build/repo/pages/requisitions/new/index.vue?macro=true";
import { default as reviewaDBasOd_O_45u_45kYhksZeTciwQXnT3KDWPbvvOgcZWSuEMeta } from "/opt/build/repo/pages/requisitions/new/review.vue?macro=true";
import { default as approvalJgOXjX28H_45DY_45svcEnXZhcx9YGZoiNB1Ur5N2eFu42kMeta } from "/opt/build/repo/pages/requisitions/new/approval.vue?macro=true";
import { default as deliveryqxDr82h7Td5d_45AxBc1u1EoShTZECmQmBbjVSqGRuVKIMeta } from "/opt/build/repo/pages/requisitions/new/delivery.vue?macro=true";
import { default as newqJgyIzdTOzOXlmlIQXf8qv8eMv5XFVsXJF51awegkScMeta } from "/opt/build/repo/pages/requisitions/new.vue?macro=true";
import { default as editMo0HXlBs_0bnutKrnBJVabj54kD9rNFSDDlC4jU5JhQMeta } from "/opt/build/repo/pages/requisitions/[id]/edit.vue?macro=true";
export default [
  {
    name: adminoDxSfL1zfbtXOZhTEjupMIsD_45tLPMDhEkobqeqFztjkMeta?.name,
    path: "/admin",
    meta: adminoDxSfL1zfbtXOZhTEjupMIsD_45tLPMDhEkobqeqFztjkMeta || {},
    component: () => import("/opt/build/repo/pages/admin.vue"),
    children: [
  {
    name: "admin",
    path: "",
    meta: indexB_JFzTrHKOxKCfg98NsHuB9u88BRvwFHHo3pJyQgpCEMeta || {},
    component: () => import("/opt/build/repo/pages/admin/index.vue")
  },
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/opt/build/repo/pages/admin/emails/index.vue")
  },
  {
    name: "admin-dashboard",
    path: "dashboard",
    component: () => import("/opt/build/repo/pages/admin/dashboard/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexaAs6F0uOO1PbNxqH8DD_45e04gSZQCarVD9TCXJYV94KEMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logindGU_Y4tTcWsCg9HnKfbgxniTJNnqn8Xqf4N3mbSf_459QMeta || {},
    component: () => import("/opt/build/repo/pages/login.vue")
  },
  {
    name: profileYXN2cSixlL75SwObUUSDSPm9Xr5hPfQHYdM_45txMKyrEMeta?.name,
    path: "/profile",
    meta: profileYXN2cSixlL75SwObUUSDSPm9Xr5hPfQHYdM_45txMKyrEMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue"),
    children: [
  {
    name: "profile",
    path: "",
    component: () => import("/opt/build/repo/pages/profile/index.vue")
  },
  {
    name: "profile-password",
    path: "password",
    component: () => import("/opt/build/repo/pages/profile/password.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/opt/build/repo/pages/profile/notifications.vue")
  }
]
  },
  {
    name: expenseszSYrxJ4pkAgmqya1zPeOh5mSY6YFECca_M_bshqH6SIMeta?.name,
    path: "/expenses",
    meta: expenseszSYrxJ4pkAgmqya1zPeOh5mSY6YFECca_M_bshqH6SIMeta || {},
    component: () => import("/opt/build/repo/pages/expenses.vue"),
    children: [
  {
    name: "expenses-new",
    path: "new",
    component: () => import("/opt/build/repo/pages/expenses/new.vue")
  },
  {
    name: _91id_93LL7vsE0nSFJredRTWbC17m91d84hBmm7nNBQIbr0TA8Meta?.name,
    path: ":id()",
    meta: _91id_93LL7vsE0nSFJredRTWbC17m91d84hBmm7nNBQIbr0TA8Meta || {},
    component: () => import("/opt/build/repo/pages/expenses/[id].vue"),
    children: [
  {
    name: "expenses-id-edit",
    path: "edit",
    meta: editGyF4glqLoO1oRVFhuraXz_45uMijB0zpopo71gf8bY7ccMeta || {},
    component: () => import("/opt/build/repo/pages/expenses/[id]/edit.vue")
  },
  {
    name: "expenses-id",
    path: "",
    component: () => import("/opt/build/repo/pages/expenses/[id]/index.vue")
  }
]
  },
  {
    name: "expenses",
    path: "",
    component: () => import("/opt/build/repo/pages/expenses/index.vue")
  },
  {
    name: "expenses-export",
    path: "export",
    component: () => import("/opt/build/repo/pages/expenses/export.vue")
  }
]
  },
  {
    name: invoicesksZypTfdfNkd37B_45ULP3MP9EobbXZ_45Air6nIR1UYV2MMeta?.name,
    path: "/invoices",
    meta: invoicesksZypTfdfNkd37B_45ULP3MP9EobbXZ_45Air6nIR1UYV2MMeta || {},
    component: () => import("/opt/build/repo/pages/invoices.vue"),
    children: [
  {
    name: "invoices-inbox",
    path: "inbox",
    component: () => import("/opt/build/repo/pages/invoices/inbox.vue")
  },
  {
    name: "invoices",
    path: "",
    meta: index0Di72goBjyLuq31l5hhV78_45uqtDkILlexkn05WIHbGAMeta || {},
    component: () => import("/opt/build/repo/pages/invoices/index.vue"),
    children: [
  {
    name: "invoices-index-export",
    path: "export",
    component: () => import("/opt/build/repo/pages/invoices/index/export.vue")
  }
]
  }
]
  },
  {
    name: dashboarddIGQPcp2ZQEz5u2XKJHyyygOFdfmIecOhuIjtCy_6i0Meta?.name,
    path: "/dashboard",
    meta: dashboarddIGQPcp2ZQEz5u2XKJHyyygOFdfmIecOhuIjtCy_6i0Meta || {},
    component: () => import("/opt/build/repo/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-all",
    path: "all",
    component: () => import("/opt/build/repo/pages/dashboard/all.vue")
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-my-orders",
    path: "my-orders",
    component: () => import("/opt/build/repo/pages/dashboard/my-orders.vue")
  }
]
  },
  {
    name: suppliers6P25SzaXCJvxgtJhD7nKcsWhzypD51y2KJkd3W_qc2oMeta?.name,
    path: "/suppliers",
    meta: suppliers6P25SzaXCJvxgtJhD7nKcsWhzypD51y2KJkd3W_qc2oMeta || {},
    component: () => import("/opt/build/repo/pages/suppliers.vue"),
    children: [
  {
    name: new5tuZc0YebIKdTKEs_LUdmG_45UqIVEYHx49D0i6yG6bf0Meta?.name,
    path: "new",
    component: () => import("/opt/build/repo/pages/suppliers/new.vue"),
    children: [
  {
    name: "suppliers-new",
    path: "",
    component: () => import("/opt/build/repo/pages/suppliers/new/index.vue")
  },
  {
    name: "suppliers-new-categories",
    path: "categories",
    component: () => import("/opt/build/repo/pages/suppliers/new/categories.vue")
  },
  {
    name: "suppliers-new-contacts-new",
    path: "contacts/new",
    component: () => import("/opt/build/repo/pages/suppliers/new/contacts/new.vue")
  },
  {
    name: "suppliers-new-contacts",
    path: "contacts",
    component: () => import("/opt/build/repo/pages/suppliers/new/contacts/index.vue")
  }
]
  },
  {
    name: "suppliers-id",
    path: ":id()",
    component: () => import("/opt/build/repo/pages/suppliers/[id].vue"),
    children: [
  {
    name: editOxXru9nr3qSUpW7YNsqSdT0Ab_pQtEMFQtlRkacIVcwMeta?.name,
    path: "edit",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit.vue"),
    children: [
  {
    name: "suppliers-id-edit",
    path: "",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/index.vue")
  },
  {
    name: "suppliers-id-edit-companies",
    path: "companies",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/companies.vue")
  },
  {
    name: "suppliers-id-edit-categories",
    path: "categories",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/categories.vue")
  },
  {
    name: "suppliers-id-edit-contacts-new",
    path: "contacts/new",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/contacts/new.vue")
  },
  {
    name: "suppliers-id-edit-contacts",
    path: "contacts",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/contacts/index.vue")
  },
  {
    name: "suppliers-id-edit-contacts-contactId",
    path: "contacts/:contactId()",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/contacts/[contactId].vue")
  }
]
  },
  {
    name: "suppliers-id-merge",
    path: "merge",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/merge.vue")
  }
]
  },
  {
    name: "suppliers",
    path: "",
    component: () => import("/opt/build/repo/pages/suppliers/index.vue")
  }
]
  },
  {
    name: "users-new",
    path: "/users/new",
    meta: new6nHWqb7LCv1Qrq3Qc2QOvIi0eQr1a6OPovY3LHqXSiQMeta || {},
    component: () => import("/opt/build/repo/pages/users/new.vue")
  },
  {
    name: accountingf_OXI0_pNwENm_45qDFv4BWI64aTXFhkdmjkEwn28zUcwMeta?.name,
    path: "/accounting",
    meta: accountingf_OXI0_pNwENm_45qDFv4BWI64aTXFhkdmjkEwn28zUcwMeta || {},
    component: () => import("/opt/build/repo/pages/accounting.vue"),
    children: [
  {
    name: "accounting",
    path: "",
    component: () => import("/opt/build/repo/pages/accounting/index.vue")
  },
  {
    name: "accounting-pending-sync",
    path: "pending-sync",
    component: () => import("/opt/build/repo/pages/accounting/pending-sync.vue")
  }
]
  },
  {
    name: "components",
    path: "/components",
    meta: componentseh7D6mnBjAg7IfCAGQxCfAmMQv2_LEwhjTfpW5a_6jwMeta || {},
    component: () => import("/opt/build/repo/pages/components.vue")
  },
  {
    name: newCn5JSGaSW5Odvg8ELyE8by_18bLinxlbGNp5FnIC4Y4Meta?.name,
    path: "/orders/new",
    meta: newCn5JSGaSW5Odvg8ELyE8by_18bLinxlbGNp5FnIC4Y4Meta || {},
    component: () => import("/opt/build/repo/pages/orders/new.vue"),
    children: [
  {
    name: "orders-new",
    path: "",
    component: () => import("/opt/build/repo/pages/orders/new/index.vue")
  },
  {
    name: "orders-new-review",
    path: "review",
    component: () => import("/opt/build/repo/pages/orders/new/review.vue")
  },
  {
    name: "orders-new-delivery",
    path: "delivery",
    component: () => import("/opt/build/repo/pages/orders/new/delivery.vue")
  },
  {
    name: "orders-new-invite-suppliers",
    path: "invite-suppliers",
    component: () => import("/opt/build/repo/pages/orders/new/invite-suppliers.vue")
  }
]
  },
  {
    name: "setup-mode",
    path: "/setup-mode",
    component: () => import("/opt/build/repo/pages/setup-mode.vue")
  },
  {
    name: _91id_93dWQGHkP2ZiXwthg3lYEbXH_45RM9VptslrhcqPfONkXLwMeta?.name,
    path: "/orders/:id()",
    meta: _91id_93dWQGHkP2ZiXwthg3lYEbXH_45RM9VptslrhcqPfONkXLwMeta || {},
    component: () => import("/opt/build/repo/pages/orders/[id].vue"),
    children: [
  {
    name: "orders-id-edit",
    path: "edit",
    component: () => import("/opt/build/repo/pages/orders/[id]/edit.vue")
  },
  {
    name: "orders-id",
    path: "",
    component: () => import("/opt/build/repo/pages/orders/[id]/index.vue")
  },
  {
    name: receiveRdOoF_2bF5xTx9Ek3QfW8_WwbBzI8_45XoO_45CeVKN7_D0Meta?.name,
    path: "receive",
    component: () => import("/opt/build/repo/pages/orders/[id]/receive.vue"),
    children: [
  {
    name: "orders-id-receive-full",
    path: "full",
    component: () => import("/opt/build/repo/pages/orders/[id]/receive/full.vue")
  },
  {
    name: "orders-id-receive",
    path: "",
    component: () => import("/opt/build/repo/pages/orders/[id]/receive/index.vue")
  },
  {
    name: "orders-id-receive-submit",
    path: "submit",
    component: () => import("/opt/build/repo/pages/orders/[id]/receive/submit.vue")
  },
  {
    name: "orders-id-receive-partial",
    path: "partial",
    component: () => import("/opt/build/repo/pages/orders/[id]/receive/partial.vue")
  }
]
  },
  {
    name: "orders-id-in-store",
    path: "in-store",
    component: () => import("/opt/build/repo/pages/orders/[id]/in-store.vue")
  },
  {
    name: "orders-id-bom-pricing",
    path: "bom-pricing",
    component: () => import("/opt/build/repo/pages/orders/[id]/bom-pricing.vue")
  },
  {
    name: change_45orderWcrJ28nSWAeoEy9Ba3xbMbQ0yFu_OS0JXleX0hAYf9sMeta?.name,
    path: "change-order",
    component: () => import("/opt/build/repo/pages/orders/[id]/change-order.vue"),
    children: [
  {
    name: "orders-id-change-order",
    path: "",
    component: () => import("/opt/build/repo/pages/orders/[id]/change-order/index.vue")
  },
  {
    name: "orders-id-change-order-bom-items",
    path: "bom-items",
    meta: bom_45itemsk12HKFRCUKdQkYaPfC2GDBkx9XsO_45XiFGv0zubnO4vAMeta || {},
    component: () => import("/opt/build/repo/pages/orders/[id]/change-order/bom-items.vue")
  }
]
  },
  {
    name: complete_45draftLYD_2C85VuyTkL_45XTXZcjXjSPKpvg3_BKAVizj44DssMeta?.name,
    path: "complete-draft",
    component: () => import("/opt/build/repo/pages/orders/[id]/complete-draft.vue"),
    children: [
  {
    name: "orders-id-complete-draft",
    path: "",
    component: () => import("/opt/build/repo/pages/orders/[id]/complete-draft/index.vue")
  },
  {
    name: "orders-id-complete-draft-bom-items",
    path: "bom-items",
    meta: bom_45itemsr2VASGPugj6lrUReim2Od0XqTlCbIOq6Y7q2QBbvYIMMeta || {},
    component: () => import("/opt/build/repo/pages/orders/[id]/complete-draft/bom-items.vue")
  }
]
  },
  {
    name: "orders-id-quotes-quote",
    path: "quotes/:quote()",
    component: () => import("/opt/build/repo/pages/orders/[id]/quotes/[quote].vue")
  },
  {
    name: "orders-id-invite-suppliers",
    path: "invite-suppliers",
    component: () => import("/opt/build/repo/pages/orders/[id]/invite-suppliers.vue")
  },
  {
    name: "orders-id-invoices-invoice",
    path: "invoices/:invoice()",
    component: () => import("/opt/build/repo/pages/orders/[id]/invoices/[invoice].vue")
  }
]
  },
  {
    name: "users",
    path: "/users",
    meta: index1mG8JMQgCyyObX5use_45xFbRcEJCfqtvHcFdfbQ88imoMeta || {},
    component: () => import("/opt/build/repo/pages/users/index.vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexOlWd9vFHguHOsQSBcDz6x2XeFrcv2t5_45c0Q9Cv_45IO0oMeta || {},
    component: () => import("/opt/build/repo/pages/orders/index.vue")
  },
  {
    name: "projects-new",
    path: "/projects/new",
    meta: newjprGiRrRDrv4NOAm0bLS9GBcvkWEGuX_45NolO3FeAX3kMeta || {},
    component: () => import("/opt/build/repo/pages/projects/new.vue")
  },
  {
    name: requisitionsO_45Ch7ICMFhaShjRCDBpEqzh8VR8_45lakmBQ98H415idQMeta?.name,
    path: "/requisitions",
    meta: requisitionsO_45Ch7ICMFhaShjRCDBpEqzh8VR8_45lakmBQ98H415idQMeta || {},
    component: () => import("/opt/build/repo/pages/requisitions.vue"),
    children: [
  {
    name: "requisitions",
    path: "",
    component: () => import("/opt/build/repo/pages/requisitions/index.vue")
  },
  {
    name: "requisitions-id",
    path: ":id()",
    meta: indexnAKVgOczf25AS5BM5MNTvnnSDIoJLkFNj6AG55Q_45I94Meta || {},
    component: () => import("/opt/build/repo/pages/requisitions/[id]/index.vue")
  }
]
  },
  {
    name: _91id_93Hz9G7CVSytVYMe3Ox5AziiJMxgFROVbocG1I3Lb9q5MMeta?.name,
    path: "/projects/:id()",
    component: () => import("/opt/build/repo/pages/projects/[id].vue"),
    children: [
  {
    name: "projects-id",
    path: "",
    meta: indexBX58bQISY1VYo_Wz8CU5cAz0R5YuROecMIV6BeXO8K4Meta || {},
    component: () => import("/opt/build/repo/pages/projects/[id]/index.vue")
  },
  {
    name: _91bomId_93VuOpXo_TnMpXTnST3V4sErEOvuQaAc_LsWpUA7pRCcMMeta?.name,
    path: "boms/:bomId()",
    meta: _91bomId_93VuOpXo_TnMpXTnST3V4sErEOvuQaAc_LsWpUA7pRCcMMeta || {},
    component: () => import("/opt/build/repo/pages/projects/[id]/boms/[bomId].vue"),
    children: [
  {
    name: "projects-id-boms-bomId",
    path: "",
    component: () => import("/opt/build/repo/pages/projects/[id]/boms/[bomId]/index.vue")
  },
  {
    name: "projects-id-boms-bomId-orders",
    path: "orders",
    component: () => import("/opt/build/repo/pages/projects/[id]/boms/[bomId]/orders.vue")
  }
]
  }
]
  },
  {
    name: _91id_93DhQeBoA4kM2PPi3xms_45_wQbSiAYh4f7fFiwIVmhB9JYMeta?.name,
    path: "/companies/:id()",
    meta: _91id_93DhQeBoA4kM2PPi3xms_45_wQbSiAYh4f7fFiwIVmhB9JYMeta || {},
    component: () => import("/opt/build/repo/pages/companies/[id].vue"),
    children: [
  {
    name: "companies-id",
    path: "",
    component: () => import("/opt/build/repo/pages/companies/[id]/index.vue")
  },
  {
    name: "companies-id-roles",
    path: "roles",
    component: () => import("/opt/build/repo/pages/companies/[id]/roles.vue")
  },
  {
    name: "companies-id-contacts",
    path: "contacts",
    component: () => import("/opt/build/repo/pages/companies/[id]/contacts.vue")
  },
  {
    name: "companies-id-cost-codes",
    path: "cost-codes",
    component: () => import("/opt/build/repo/pages/companies/[id]/cost-codes.vue")
  },
  {
    name: "companies-id-restricted",
    path: "restricted",
    meta: restricteda77Tx3jSab_453rCdoyxlkU3llUogGjeYFQSB3CcW_45z8cMeta || {},
    component: () => import("/opt/build/repo/pages/companies/[id]/restricted.vue")
  },
  {
    name: "companies-id-report-config",
    path: "report-config",
    component: () => import("/opt/build/repo/pages/companies/[id]/report-config.vue")
  },
  {
    name: "companies-id-purchase-order",
    path: "purchase-order",
    component: () => import("/opt/build/repo/pages/companies/[id]/purchase-order.vue")
  },
  {
    name: "companies-id-accounting",
    path: "accounting",
    meta: index7zRULB6gZIH0r5NNoDr8yIF1GZYiC1Y6AIIMghtlUhMMeta || {},
    component: () => import("/opt/build/repo/pages/companies/[id]/accounting/index.vue")
  },
  {
    name: "companies-id-accounting-setup",
    path: "accounting/setup",
    meta: setupihkskdtKJn_45hG4oNJ_sar0Bl6Gfa5hLi7ZujbOIYOZAMeta || {},
    component: () => import("/opt/build/repo/pages/companies/[id]/accounting/setup.vue")
  }
]
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexJxu2jFkvSCWJf6gZ2nAdBaEA0U3aznS_LcN5i2h40XgMeta || {},
    component: () => import("/opt/build/repo/pages/projects/index.vue")
  },
  {
    name: forgot_45passwordOIFCeCN4AMh8hgrToHpDpWjsGVd3MBWi_zvta3PbADwMeta?.name,
    path: "/forgot-password",
    meta: forgot_45passwordOIFCeCN4AMh8hgrToHpDpWjsGVd3MBWi_zvta3PbADwMeta || {},
    component: () => import("/opt/build/repo/pages/forgot-password.vue"),
    children: [
  {
    name: "forgot-password",
    path: "",
    component: () => import("/opt/build/repo/pages/forgot-password/index.vue")
  },
  {
    name: "forgot-password-check-email",
    path: "check-email",
    component: () => import("/opt/build/repo/pages/forgot-password/check-email.vue")
  },
  {
    name: "forgot-password-uuid-reset",
    path: ":uuid()/reset",
    component: () => import("/opt/build/repo/pages/forgot-password/[uuid]/reset.vue")
  }
]
  },
  {
    name: "users-id-edit",
    path: "/users/:id()/edit",
    meta: edit4btEqLweB2QXRaj9U1fiz1FH9nLtogs8S_458SnIhq_AkMeta || {},
    component: () => import("/opt/build/repo/pages/users/[id]/edit.vue")
  },
  {
    name: "error-test",
    path: "/error-test",
    meta: index_45W_cGPvNZ4K2mhxuFSxIRJM87Ye5vsIuPoUUsDX2r70Meta || {},
    component: () => import("/opt/build/repo/pages/error-test/index.vue")
  },
  {
    name: accounting_45exporti_45eQjERxm4NAAeSrtJZIXgyD_iKtbRc8nOaWFXxD5sEMeta?.name,
    path: "/accounting-export",
    meta: accounting_45exporti_45eQjERxm4NAAeSrtJZIXgyD_iKtbRc8nOaWFXxD5sEMeta || {},
    component: () => import("/opt/build/repo/pages/accounting-export.vue"),
    children: [
  {
    name: "accounting-export",
    path: "",
    component: () => import("/opt/build/repo/pages/accounting-export/index.vue")
  },
  {
    name: "accounting-export-order",
    path: ":order()",
    component: () => import("/opt/build/repo/pages/accounting-export/[order].vue")
  }
]
  },
  {
    name: new0qSXK1sVifMz9QtUEj0DKeuXimv0MavYJgtimw4uCmYMeta?.name,
    path: "/purchase-orders/new",
    meta: new0qSXK1sVifMz9QtUEj0DKeuXimv0MavYJgtimw4uCmYMeta || {},
    component: () => import("/opt/build/repo/pages/purchase-orders/new.vue"),
    children: [
  {
    name: "purchase-orders-new",
    path: "",
    component: () => import("/opt/build/repo/pages/purchase-orders/new/index.vue")
  },
  {
    name: "purchase-orders-new-bom-items",
    path: "bom-items",
    meta: bom_45itemsiTOOKPan8CNHmasUrrcymjt6xMkCIV6UK0Ylma4DSP4Meta || {},
    component: () => import("/opt/build/repo/pages/purchase-orders/new/bom-items.vue")
  }
]
  },
  {
    name: "users-accept-invite-uuid",
    path: "/users/accept-invite/:uuid()",
    meta: index4iEvdLIe7_N_45WfWuhZjpfiNgtrKigc10ftdSWNuEnEgMeta || {},
    component: () => import("/opt/build/repo/pages/users/accept-invite/[uuid]/index.vue")
  },
  {
    name: suppliersCg_45qTwztRQ3MMn8dTzFowm2HIsm6elSqDJApT5fKbKgMeta?.name,
    path: "/companies/:id()/suppliers",
    meta: suppliersCg_45qTwztRQ3MMn8dTzFowm2HIsm6elSqDJApT5fKbKgMeta || {},
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers.vue"),
    children: [
  {
    name: adduZuFFVVfqIYisRcDnBTpWN05W1lYEfyvHP3DfSaS6hsMeta?.name,
    path: "add",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add.vue"),
    children: [
  {
    name: "companies-id-suppliers-add-new",
    path: "new",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/new.vue")
  },
  {
    name: "companies-id-suppliers-add",
    path: "",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/index.vue")
  },
  {
    name: "companies-id-suppliers-add-contacts",
    path: "contacts",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/contacts.vue")
  },
  {
    name: "companies-id-suppliers-add-in-store",
    path: "in-store",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/in-store.vue")
  },
  {
    name: "companies-id-suppliers-add-categories",
    path: "categories",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/categories.vue")
  }
]
  },
  {
    name: "companies-id-suppliers",
    path: "",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/index.vue")
  },
  {
    name: _91companyVendorId_93YJrVPbKIon5f5liOLS22GIv1ccqwAumJoHuZ_45if_45H8IMeta?.name,
    path: ":companyVendorId()",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId].vue"),
    children: [
  {
    name: "companies-id-suppliers-companyVendorId-map",
    path: "map",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/map.vue")
  },
  {
    name: "companies-id-suppliers-companyVendorId",
    path: "",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/index.vue")
  },
  {
    name: "companies-id-suppliers-companyVendorId-contacts",
    path: "contacts",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/contacts.vue")
  },
  {
    name: "companies-id-suppliers-companyVendorId-accounting",
    path: "accounting",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/accounting.vue")
  }
]
  }
]
  },
  {
    name: newgMUkRG1HRAaZ8RoZepmT7WnRoxwZMGmYvIyZommmfzcMeta?.name,
    path: "/invoices/new",
    meta: newgMUkRG1HRAaZ8RoZepmT7WnRoxwZMGmYvIyZommmfzcMeta || {},
    component: () => import("/opt/build/repo/pages/invoices/new.vue"),
    children: [
  {
    name: "invoices-new",
    path: "",
    component: () => import("/opt/build/repo/pages/invoices/new/index.vue")
  },
  {
    name: "invoices-new-match-items",
    path: "match-items",
    component: () => import("/opt/build/repo/pages/invoices/new/match-items.vue")
  }
]
  },
  {
    name: "invoices-id",
    path: "/invoices/:id()",
    meta: _91id_93C_45KioyoIitjEJE4tbMUV7enJuENTeabk3i72oWt_taIMeta || {},
    component: () => import("/opt/build/repo/pages/invoices/[id].vue"),
    children: [
  {
    name: "invoices-id-edit",
    path: "edit",
    component: () => import("/opt/build/repo/pages/invoices/[id]/edit.vue")
  },
  {
    name: "invoices-id-match-items",
    path: "match-items",
    component: () => import("/opt/build/repo/pages/invoices/[id]/match-items.vue")
  }
]
  },
  {
    name: newqJgyIzdTOzOXlmlIQXf8qv8eMv5XFVsXJF51awegkScMeta?.name,
    path: "/requisitions/new",
    meta: newqJgyIzdTOzOXlmlIQXf8qv8eMv5XFVsXJF51awegkScMeta || {},
    component: () => import("/opt/build/repo/pages/requisitions/new.vue"),
    children: [
  {
    name: "requisitions-new",
    path: "",
    component: () => import("/opt/build/repo/pages/requisitions/new/index.vue")
  },
  {
    name: "requisitions-new-review",
    path: "review",
    component: () => import("/opt/build/repo/pages/requisitions/new/review.vue")
  },
  {
    name: "requisitions-new-approval",
    path: "approval",
    component: () => import("/opt/build/repo/pages/requisitions/new/approval.vue")
  },
  {
    name: "requisitions-new-delivery",
    path: "delivery",
    component: () => import("/opt/build/repo/pages/requisitions/new/delivery.vue")
  }
]
  },
  {
    name: "requisitions-id-edit",
    path: "/requisitions/:id()/edit",
    meta: editMo0HXlBs_0bnutKrnBJVabj54kD9rNFSDDlC4jU5JhQMeta || {},
    component: () => import("/opt/build/repo/pages/requisitions/[id]/edit.vue")
  }
]