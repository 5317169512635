<template>
  <tr>
    <td class="text-no-wrap" v-text="item.item_number" />
    <td class="text-left">
      <div v-text="item.description" />
      <div class="text-mid-grey" v-text="item.comment" />
      <div v-if="bom">
        <nuxt-link
          class="text-decoration-none"
          :to="{ name: 'projects-id-boms-bomId', params: { id: bom.project, bomId: bom.bill_of_materials } }"
        >
          <v-icon icon="mdi-link" />
          {{ bom.bill_of_materials_title }}
        </nuxt-link>
      </div>
      <div v-if="withReceived && partialReceipt" class="text-primary">
        Incomplete
      </div>
    </td>
    <order-rfq-columns
      v-if="fromBom"
      :item="item"
      :boms="boms"
      :quotes="quotes"
    />
    <template v-else>
      <td v-if="withDuration">
        <span v-if="rental">
          {{ item.rental_duration }} {{ item.rental_duration_unit }}
        </span>
        <span v-else>
          -
        </span>
      </td>
      <td>
        <div class="text-left">
          {{ Number(item.quantity) }} <span v-if="!forQuote" v-text="item.unit" />
        </div>
      </td>
      <td v-if="forQuote" v-text="item.unit" />
      <td v-if="withReceived">
        <div class="text-left">
          <span :class="{ 'text-primary': partialReceipt }" v-text="Number(item.received_quantity)" />
          {{ item.unit }}
        </div>
      </td>
      <td class="text-no-wrap text-right">
        <span :title="hasRentalPrice ? `per ${item.rental_price.per}` : undefined">
          {{ currency(unitPrice) }}
        </span>
      </td>
      <td class="text-no-wrap text-right">
        {{ currency(subTotal) }}
      </td>
      <td v-if="!forQuote" v-text="item.cost_code" />
    </template>
  </tr>
</template>

<script setup lang='ts'>
import orderRfqColumns from '@/components/orders/order-rfq-columns.vue'
import { currency } from '~/models/filters'
import type { LineItem } from '@quotetome/materials-api'

export interface Props {
  forQuote?: boolean,
  fromBom?: boolean,
  boms?: any[],
  item: LineItem,
  quotes?: any[],
  withDuration?: boolean,
  withReceived?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  boms: () => [],
  quotes: () => [],
})

const bom = computed(() => (props.boms.find(bo => (
  bo.purchase_order_item?.id === props.item.id
  || bo.order_item_identifier === props.item.internal_identifier
))))

const partialReceipt = computed(() => Number(props.item.quantity) !== Number(props.item.received_quantity))

const rental = computed(() => !!props.item.rental_duration)

const hasRentalPrice = computed(() => rental.value && props.item.rental_price)

const subTotal = computed(() => {
  const price = (hasRentalPrice.value ? props.item.rental_price.price : props.item.unit_price) || 0
  return Number(props.item.quantity) * price
})

const unitPrice = computed(() => (hasRentalPrice.value ? props.item.rental_price.rate : props.item.unit_price))

</script>
